import ForceDirected from './components/ForceDirected';
import { ReactComponent as BicycleSVG } from "./bicycle.svg";

function App() {
  return (
    <div className='h-screen flex flex-col'>
      <header className="py-4 border-b-2 border-gray-50">
        <div className="container mx-auto flex items-center">
          <BicycleSVG className="h-6 w-6 mr-2 text-green-800" />
          <h1 className="text-lg font-black text-green-800">YAY.BIKE</h1>
        </div>
      </header>
      <ForceDirected/>
    </div>
  );
}

export default App;

import { useEffect, useState, useRef } from "react";
import { forceX, forceY, forceSimulation, forceLink, forceManyBody, forceCenter, forceCollide } from "d3-force";
import './ForceDirected.css';


function getData(data, width, height, nodeSize) {
  const _data = JSON.parse(JSON.stringify(data));
  const _simulation = forceSimulation(_data.nodes)
    .force("link", forceLink(_data.links).id(d => d.id))
    .force("charge", forceManyBody().strength(nodeSize * 5))
    .force("x", forceX())
    .force("y", forceY())
    .force("center", forceCenter(width / 2, height))
    .force("collide", forceCollide().radius(d => nodeSize).iterations(10))
    .stop();

  _simulation.tick(Math.ceil(Math.log(_simulation.alphaMin()) / Math.log(1 - _simulation.alphaDecay())));

  return _data;
}


export default function ForceDirected() {
  const container = useRef();
  const canvas = useRef();
  const nodeSize = 50;
  const [data, setData] = useState({ nodes: [], links: []});
  const [boundingClientRect, setBoundingClientRect] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('http://localhost:8787'); // TODO
        const newData = await response.json();
        setData(newData);
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, [])

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setBoundingClientRect(container.current.getBoundingClientRect())
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    const { width, height } = boundingClientRect;
    if (!data) { return; }
    const newLayoutData = getData(data, width, height, nodeSize)

    const canvasEl = canvas.current.getContext("2d"); // TODO: use ref instead
    canvasEl.clearRect(0, 0, width, height * 2);

    newLayoutData.links.forEach(link => {
      canvasEl.beginPath();
      canvasEl.strokeStyle = "gray";
      canvasEl.strokeWidth = link.strength || 1;
      canvasEl.moveTo(link.source.x, link.source.y);
      canvasEl.lineTo(link.target.x, link.target.y);
      canvasEl.stroke();
      canvasEl.closePath();
    })

    newLayoutData.nodes.forEach(node => {
      if (node.thumbnail?.url) {
        const img = new Image();
        img.onload = () => {
          const newWidth = nodeSize;
          const newHeight = newWidth * (node.thumbnail.height / node.thumbnail.width);
          canvasEl.fillStyle = "white";
          canvasEl.fillRect(node.x - newWidth / 2, node.y - newHeight / 2 , newWidth, newHeight);
          canvasEl.strokeRect(node.x - newWidth / 2 - 1, node.y - newHeight / 2 - 1, newWidth + 2, newHeight + 2);
          canvasEl.drawImage(img, node.x - newWidth / 2, node.y - newHeight / 2, newWidth, newHeight);
        };
        img.src = node.thumbnail.url;
      } else {
        canvasEl.beginPath();
        canvasEl.arc(node.x, node.y, nodeSize / 2, 0, Math.PI * 2);
        canvasEl.fillStyle = "gray";
        canvasEl.fill();
        canvasEl.closePath();
      }
    })
  }, [data, boundingClientRect])

  return (<div className="ForceDirected" ref={container}>
    <canvas height={boundingClientRect.height * 2} width={boundingClientRect.width} ref={canvas} onClick={(e) => console.log(e)}>
    </canvas>
  </div>)
}